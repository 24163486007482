<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
            sort-by="prod_no"
            :sort-desc="true"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{$route.name}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" small depressed @click="initialize">Refresh</v-btn>
            </v-toolbar>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                    Reset
                </v-btn>
            </template>
            <template v-slot:[`item.production_date`]="{ item }">
              {{item.production_date | moment}}
            </template>
            <template v-slot:[`item.expired_date`]="{ item }">
              {{item.delivery_date | moment}}
            </template>            
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn x-small depressed color="green" @click="inputDO(item)" class="white--text">Input DO</v-btn>
            </template>            

        </v-data-table>
      <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
      <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    </div>
</template>

<script>
  import moment from 'moment'
  export default
  {
    components: {  }, 
    data: () => ({
      headers: [
        { text: 'No. Produksi', value: 'prod_no' },
        { text: 'Tanggal Produksi', value: 'production_date' },
        { text: 'Tanggal Kirim', value: 'delivery_date' },
        { text: 'Group', value: 'order_category' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      search: '',
      saved: false,
      saveError: false,
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodStatus() {
        return this.miscCodes.filter(element => {
          return element.type=='Status Produksi'
        })
      }
    },

    watch: {
    },

    created () {
      this.initialize()
    },
    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
    },

    methods: {
      async initialize () {
        this.items = (await this.$store.dispatch('production/getProductionsFinished')).data
        console.log(this.items)
      },

      inputDO(item) {
        this.$store.commit('deliveryorder/setObj', item)
        this.$router.push(`/deliveryorder/${item.prod_no}/${item.order_category}`)
        console.log(item)
      }
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>